const siderBar = {
    'aladdin.home': '首页',
    'aladdin.notification': '通知',
    'aladdin.transaction': '交易管理',
    'aladdin.admin': '管理员',

    'aladdin.crm': '客户管理',
    'aladdin.configuration': '运营配置管理',
    'aladdin.logout': '退出',
    'aladdin.logout.confirm': '请确认是否退出?',
    'aladdin.custody': 'Custody',
    'aladdin.wallet': 'Wallet',
    'aladdin.blockchain': 'Blockchain',
    'aladdin.position': '仓位管理',
    'aladdin.risk_control': '风控',
    'aladdin.kyc': 'KYC管理',
    'aladdin.cobo_bonus': '薪酬系统',
    'aladdin.manual_bookkeeping': '记账工具',
    'admin.team': '团队管理',
    'admin.policy': '风控规则',

    'configration.walletFinancialListPageConfigration': '财富页推荐配置',
    'configration.walletFinancialProductManagement': '理财产品模版配置',

    'configration.MortgageOrderManagement': '借贷订单管理',
    'configration.walletUpdateMessage': 'Wallet更新提示',
    'configration.publicMessage': 'Wallet公告配置',
    'configration.bannerConfig': 'Banner配置',
    'configration.rewardConfig': '运营活动发奖',
    'configration.financialClassifyConfig': '财富分类配置',
    'configration.circleConfig': '圈人配置',
    'configration.namelistConfig': '圈人名单配置',
    'configration.mobileComponentConfig': '移动端组件配置',
    'configration.mortgageCustomizeConfig': '借贷客户订制',
    'configration.mortgageLoanCoinInfoConfig': '借贷币种可借数量配置',
    'configration.mortgageCoinPairConfig': '借贷币对配置',
    'configration.mortgageRepaymentConfig': '借贷还款方式配置',
    'configration.mortgageDurationConfig': '借贷还款周期配置',
    'configration.mortgageOrderEdit': '借贷订单编辑',
    'configration.mortgageBannerConfig': '借贷Banner编辑',
    'configration.exchange': '闪兑配置',
    'configration.hodlProductConfig': '屯币产品配置',
    'configration.hodlCustomizeConfig': '屯币活动配置',
    'configration.hodlSupportCoinConfig': '屯币支持币种配置',
    'configration.custodySettleRate': '理财产品收益率配置',

    'crm.cobo-wallet-user': 'Wallet用户查询',
    'crm.hd-wallet-user': 'HD钱包',
    'crm.vault-user': '硬件钱包',
    'crm.custody-user': 'Custody',
    // "crm.custodyUserSandbox": "Custody用户(沙盒环境)",
    // 'crm.custodyUserProduct': 'Custody用户',
    // 'crm.sms': '短信发送查询',
    'crm.walletUserMangerManagement': 'Wallet用户专属客服',
    // 'crm.clienteleManagement': '客户管理',

    // Position
    'position.PositionManagement': '仓位管理',
    'notification.unknown': '??',

    'transaction.pending-approval': '云端用户待审批交易',
    'transaction.internal-pending-approval': '云端待审批内部转账',
    'transaction.transaction-explorer': '系统钱包转账',
    'transaction.suspended-management': '交易充提管理',
    'transaction.local-transaction-explorer': '系统钱包转账',
    'transaction.custody-transaction-explorer': 'Custody交易管理',
    'transaction.custody-withdraw-explorer.old': 'Custody提现管理(旧)',
    'transaction.custody-withdraw-explorer': 'Custody提现管理',
    'transaction.custody-mpc-withdraw-explorer': 'MPC提现管理',
    'transaction.custody-withdraw-explorer-detail': 'Custody提现管理详情',
    'transaction.custody-mpc-withdraw-explorer-detail': 'MPC提现管理详情',
    'transaction.custody-large-amount-withdraw-approve-config': 'Custody大额提币流程配置',
    'transaction.inbound-transaction-management': '用户入账管理',
    'transaction.mpc-inbound-transaction-management': 'MPC入账管理',
    'transaction.wallet-verify-transaction-explorer': '活体认证交易',
    'transaction.new-internal-pending-approval': '内部活体失败交易审核',
    'transaction.new-wallet-verify-transaction-explorer': '活体认证失败交易审核',
    'transaction.local-suspended-management': '交易充提管理',
    'transaction.local-danger-management': 'Danger Coins',
    'transaction.token-management': 'Token管理',
    'transaction.tx-detail': '交易详情查询',
    'transaction.wallet-suspended-management': '交易充提管理',
    'transaction.custody-suspended-management': '交易充提管理',
    'transaction.custodyPushMessageManagement': '交易消息推送',
    'transaction.threePartyHostingApplication': 'MPC三方托管用户申请',
    'transaction.threePartyHostingList': 'MPC三方托管名单管理',

    'aladdin.user': '个人信息',
    'aladdin.404': '不存在',
    'aladdin.approvalSystem': '审批系统',

    preparing: '待开发',
    Yes: '是',
    No: '否',
    Fix: '修改',
    Edit: '修改',
    Reset: '重置',
    More: '查看',
    Cancel: '取消',
    Upload: '上传',
    Close: '关闭',
    Warning: '警告',
    Success: '操作成功',
    Information: '提示信息',
    Error: '错误信息',
    Submit: '提交',
    Next: '下一步',
    Has: '有',
    HasNot: '无',
    Off: '关',
    On: '开',
    Save: '保存',
    Approval: '审核通过',
    Unbind: '解绑',
};

const blockchain = {
    'blockchain.deposit': 'Blockchain入账审核',
    'blockchain.tokenManagement': 'Token管理',
    'blockchain.manualCollectFunds': '手动资金归集',
    'blockchain.manualAdvanceTransaction': '手动合约入账',
    'blockchain.transactionRetryResend': '交易 Retry / Resend',
};

const risk_control = {
    //    'risk_control.custodyUserAuthAdvance': '风控加速生效',
    'risk_control.custodyUserAuthAdvanceNew': '风控加速生效',
    'risk_control.custodyUserManager': '用户信息',
    'risk_control.custodyPubkeyManager': '公钥信息',
    'risk_control.custodyStatementManager': '审核信息',
    'risk_control.custodyWithdrawalOrgReviewManager': '提币Org审核记录',
    'risk_control.custodyCustomRuleManager': '客户自定义规则',
    'risk_control.authchainManager': 'Authchain信息',
};

const custody_tools = {
    'custody.addSupportedAssetCoin': 'Custody添加新币种',
    'custody.coinManage': '币种管理',
    'custody.financeConfig': '理财配置',
};

const orgkey_speedup = {
    'custody.custodyOrgkeyAuthAdvance': 'Orgkey加速生效',
};
const kyc_audit = {
    'kyc.wallet.audit': 'Wallet用户KYC',
    'kyc.custody.audit': 'Custody KYC',
    'kyc.custody.auditnew': 'Custody Kyc（新）',
    'kyc.custody.auditDetail': 'Custody Kyc 详情',
    'kyc.custody.mpcAudit': 'Custody MPC KYC',
    'kyc.custody.mpcAudit.detail': 'Custody MPC KYC 详情',
};

const cobo_bonus = {
    'cobo_bonus.employee': '员工管理',
    'cobo_bonus.bonus': '薪酬发放',
};

const manual_bookkeeping = {
    'manual_bookkeeping.transaction': '交易记录',
    'manual_bookkeeping.address': '地址标注',
};

const Sider = {
    ...siderBar,
    ...manual_bookkeeping,
    ...cobo_bonus,
    ...kyc_audit,
    ...orgkey_speedup,
    ...custody_tools,
    ...risk_control,
    ...blockchain,
};
export default Sider;
