const adminTeamUser = {
    'admin.team.tableHeader.Index': 'ID',
    'admin.team.tableHeader.Name': 'Name',
    'admin.team.tableHeader.department': 'department',
    'admin.team.tableHeader.firstName': 'first Name',
    'admin.team.tableHeader.lastName': 'last Name',

    'admin.team.tableHeader.supervisor': 'supervisor',
    'admin.team.tableHeader.firstDepartmentSupervisor': 'first Department Supervisor',

    'admin.team.tableHeader.email': 'email',
    'admin.team.tableHeader.Section': 'Authorization',
    'admin.team.tableHeader.Action': 'Action',
    'admin.team.tableHeader.action.section': 'Set Auth',
    'admin.team.tableHeader.2FAStatus': '2FA Status',
    'admin.team.tableHeader.UBKStatus': 'UBK Status',
    'admin.team.tableHeader.HodorStatus': 'Auth Status',
    'admin.team.tableHeader.Status': 'User Status',
    'admin.team.tableHeader.action.bind_ubk': 'Bind UBK',
    'admin.team.tableHeader.action.bind_hodor': 'Bind Auth',
    'admin.team.tableHeader.action.bind_hodor_search': 'Status Search',
    'admin.team.tableHeader.action.log': 'Log',
    'admin.team.tableHeader.action.reset_passwrod': 'Reset Password',
    'admin.team.tableHeader.action.reset_2fa': 'Reset 2FA',
    'admin.team.tableHeader.action.invite_again': 'Invite Again',
    'admin.team.tableHeader.action.unfreeze': 'Unfreeze',
    'admin.team.tableHeader.action.freeze': 'Freeze',
    'admin.team.tableHeader.action.reset_ubk': 'Reset UBK',
    'admin.team.tableHeader.action.reset_hodor': 'Reset Auth',
    'admin.team.tableHeader.action.show_qr': 'View QR code',

    'admin.team.table.userStatus.created': 'Inactive',
    'admin.team.table.userStatus.waiting_2fa': 'Waiting for 2FA',
    'admin.team.table.userStatus.active': 'Active',
    'admin.team.table.userStatus.deactive': 'Deactive',
    'admin.team.table.userStatus.freeze': 'Frozen',

    'admin.team.table.2fa.none': 'None',
    'admin.team.table.2fa.binding': 'Binding',
    'admin.team.table.2fa.bound': 'Bound',

    'admin.team.inviteUser.title': 'Invite User',
    'admin.team.inviteUser.refreshPermission': 'Invite User',
    'admin.team.inviteUser.name': 'Name',
    'admin.team.inviteUser.email': 'Email',
    'admin.team.inviteUser.mobile': 'Mobile',
    'admin.team.email.Invalid': 'Invalid email',
    'admin.team.name.Invalid': 'Invalid name',
    'admin.team.mobile.Invalid': 'Invalid mobile',

    'admin.team.userView.name': 'Name',
    'admin.team.userView.email': 'Email',
    'admin.team.userView.mobile': 'Mobile',
    'admin.team.userView.resetBtn': 'Reset User',
    'admin.team.confirm.resetUser.title': 'Are you sure to reset user ',

    'admin.team.confirm.hodorReset.title': 'Warning',
    'admin.team.userView.status': 'Status',
    'admin.team.confirm.hodorReset.Modal.content': 'Are you sure to reset Auth',
    'admin.team.confirm.freezeUser.Modal.content': 'Are you sure to freeze user ',
    'admin.team.confirm.unfreezeUser.Modal.content': 'Are you sure to freeze user ',
    'admin.team.queryLoop.sucess': 'Success',
    'admin.team.unfreezeUser.sucessCue': 'Finished',
    'admin.team.freezeUser.sucessCue': 'Finished',

    'admin.team.BindUBK.title': 'Bind Yubikey',
    'admin.team.BindUBK.cid': 'Client ID',
    'admin.team.BindUBK.sk': 'Yubikey SK',
    'admin.team.BindHodor.title': 'Bind Auth',
    'admin.team.BindHodor.pubkey': 'Auth pubkey',
};
export default adminTeamUser;
